import useIsMobile from "../../../hooks/useIsMobile";
import ErrorBanner from "../../../components/ErrorPage/ErrorBanner";
import Head from "next/head";
import CommanContentBanner from "../../../components/commanContentBanner";
import CommonHomeCard from "../../../components/commonHomeCard";
import { configuration } from "../../../helpers/config";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import FAQ from "../../../components/Faq";
import $ from 'jquery'; // Import jQuery

export default function GenericTemplate(props) {
  const { data, error } = props;
  const isMobile = useIsMobile();
  const titleThemeState = {
    titleColor: "",
    titleFontSize: "",
  };
  const [titleTheme, setTitleTheme] = useState(titleThemeState);

  useEffect(() => {
    setTimeout(() => {
      let urlhash = window.location.hash?.slice(1);
      document.getElementById(urlhash)?.scrollIntoView();
    },100)
  },[])

  useEffect(() => {
    setTitleTheme({
      ...titleTheme,
      titleColor: props.themeData?.cms_page_title_color,
      titleFontSize: props.themeData?.cms_page_title_font_size,
    });
  }, [props.themeData]);

  if (error) {
    return (
      <ErrorBanner
        image={`${configuration.IMG_STATIC_URL}errorBanner.png`}
        imageMob={`${configuration.IMG_STATIC_URL}errorMob.png`}
        error="404"
        desc="Sorry, the page you’re looking for doesn’t exist"
        btn="Go Back"
      />
    );
  }

  

  return (
    <div>

      <Head>
        <title>{data?.seo_settings?.meta_title}</title>
        <meta name="description" content={data?.seo_settings?.meta_desc} />
        <meta name="keywords" content={data?.seo_settings?.keywords} />
        <meta name="keywords" content={data?.seo_settings?.keywords} />
        {process.env.NEXT_PUBLIC_ALLOW_SCRIPT_TAG_PRICE=="true" && data?.primary_details?.slug=="pricelist" ?
      <script type="text/javascript">
      $(function() {
        $('.pricelist-tab li').on('click',function(){
        $(this).toggleClass('active').siblings().removeClass('active');
        if ($(this).data('value') == 'all') {
          $('.pricelist .pricelist-item').removeClass('hide');
        }
        else {
          $('.pricelist .pricelist-item').addClass('hide');
          $('.pricelist .pricelist-item.'+$(this).data("value")).removeClass('hide');
        }

      })
      })
      </script>

        :""}
      </Head>
      <div className="genericPage__container">
        {/* banner */}
        <CommanContentBanner
          classname={"genericPage__container--banner"}
          bannerSrc={
            isMobile ? data?.images?.mob_banner : data?.images?.desk_banner
          }
          videoLink={data?.video}
          altText={data?.images?.desk_banner_alt}
          bannerText={data?.primary_details?.banner_title}
          titleThemeData={titleTheme}
        />

        {/* end of banner */}
        <div id = "seosetting" className="container mx-auto px-[30px] md:px-[100px]">
          {data?.highlights && data?.highlights.length > 0 && (
            <div className="grid grid-cols-1 gap-5  md:grid-cols-3">
              {data?.highlights.map((item, index) => (
                <CommonHomeCard
                  key={index}
                  imgSrc={`${configuration.IMG_URL}${item?.image}`}
                  imgMobileSrc={`${configuration.IMG_URL}${item?.image_mob}`}
                  cardClassName="homeCms__cardLinks"
                  contentClassName={"md:max-w-[75%]"}
                  headClassName="h-[75px] lg:h-[75px] md:h-[75px] overflow-hidden"
                  descriptClass={"h-[64px] lg:h-[64px] md:h-[64px]"}
                  imgHeight={302}
                  imgWidth={545}
                  // videoLink={item?.column == 1 && !item?.isImage && page?.video}
                  imgMobileHeight={564}
                  imgMobileWidth={768}
                  cardTitle={item?.title}
                  cardDescription={item?.desc}
                  cardBtnText={"SEE DETAILS"}
                  cardLink={item?.ctaLink}
                  btnType={"button_dark_arrow"}
                />
              ))}
            </div>
          )}
          <h1 className="genericPage__container--title">
            {data?.primary_details?.title}
          </h1>
          {/*<div className="genericPage__container--shortdesc">*/}
          {/*  {data?.primary_details?.desc}*/}
          {/*</div>*/}
          <div className="genericPage__container--htmlcontent">
            <div className="unreset"
              dangerouslySetInnerHTML={{
                __html: data?.primary_details?.long_desc,
              }}
            />
            {data?.htmlContent && (
              <div className="unreset"
                dangerouslySetInnerHTML={{
                  __html: data?.htmlContent,
                }}
              ></div>
            )}
          </div>
          {/*faq code is added */}
          <FAQ faq_data={props.data}/>
        </div>
      </div>
    </div>
  );
}
