import { useEffect } from "react";
import { useRouter } from "next/router";
import { configuration } from "../helpers/config";

const RedirectComponent = (props) => {
  const router = useRouter();

  useEffect(() => {
    // Split the STATIC_ROUTES configuration and add a leading slash to each item
    const staticArray = configuration.STATIC_ROUTES?.split("/");
    const newStatic = staticArray?.map((item) => `/${item}`);

    // Check if the current route is not in the newStatic array
    if (!newStatic?.includes(router.pathname)) {
      // Redirect to the 404 page if the route is not found
      router.push("/404");
    }
  }, []);

  return null;
};

export default RedirectComponent;
