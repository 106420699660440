import React, { useEffect, useState } from "react";
import ImageWithFallback from "../components/ImageWithFallback";
import useIsMobile from "../hooks/useIsMobile";
import ReactPlayer from "react-player";
import { configuration } from "../helpers/config";
import { getImageDimensions } from "../utils/GenUtils";
import useWindowSize from "../hooks/useWindowSize";

// banner component for cms pages and other pages with title , image or video.
export default function CommanContentBanner({
  classname,
  bannerSrc,
  altText,
  videoLink,
  bannerText,
  titleThemeData,
}) {
  const isMobile = useIsMobile();
  const windoWidth = useWindowSize();

  const [bannerHeight, setBannerHeight] = useState(1004);
  const [mobileBannerHeight, setMobileBannerHeight] = useState(1080);

  useEffect(() => {
    // function to get dimensions of an image.
    getImageDimensions(`${configuration.IMG_URL}${bannerSrc}`)
      .then(({ height, width }) => {
        // if mobile, set height and accordingly
        if (isMobile) {
          if (height < 600) {
            setMobileBannerHeight(564);
          } else if (height > 600) {
            setMobileBannerHeight(1080);
          }
        } else {
          if (height < 800) {
            setBannerHeight(738);
          } else if (height > 800) {
            setBannerHeight(1004);
          }
        }
      })
      .catch((error) => {
        // console.error(error.message);
      });
  }, [bannerSrc]);
  return (
    <div
      className={`${
        classname ? classname + " container-fluid" : "container-fluid"
      } relative`}
    >
      {videoLink ? (
        <ReactPlayer
          playing={true}
          url={`${configuration.IMG_URL}${videoLink}`}
          volume={0}
          height={isMobile ? 1080 : 1004}
          width={"100%"}
          loop={true}
          playsinline
        />
      ) : (
        <ImageWithFallback
          src={
            bannerSrc
              ? `${configuration.IMG_URL}${bannerSrc}`
              : `${configuration.IMG_STATIC_URL}assets/images/cms-pages/notAvailable.jpg`
          }
          width={isMobile ? 768 : 1928}
          height={isMobile ? mobileBannerHeight : bannerHeight}
          alt={altText}
          fallback={`${configuration.IMG_STATIC_URL}assets/images/cms-pages/notAvailable.jpg`}
        />
      )}
      {bannerText && (
        <div className="container-fluid absolute bottom-[60px] z-[1] left-0 right-0">
          <div className="container">
            <h1
              className=" text-[30px] leading-[41px] md:text-[60px] md:leading-[62px] text-[#FFFFFF] uppercase md:max-w-[70%] lg:max-w-[50%]"
              style={{
                color: titleThemeData?.titleColor,
                fontSize:
                  windoWidth.width > 1024 ? titleThemeData?.titleFontSize : "",
              }}
            >
              {bannerText}
            </h1>
          </div>
        </div>
      )}
    </div>
  );
}
