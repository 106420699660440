import styles from "../../../styles/faq.module.css";
import { useEffect, useState } from "react";
import {useSelector} from "react-redux";
import style from "../../../styles/preference.module.css";
import LinkButton from "../../../components/button";
import AccordionGrid from "../../../components/accordion/AccordionGrid";
import FinanceBanner from "../../../components/FinanceBanner";
import { configuration } from "../../../helpers/config";
import { CarPageAllSections } from "../../../pages/pages/templates/all_components";
import axios from "axios";
import { toggleForm } from "../../../redux/ActionCreator";
import { useDispatch } from "react-redux";

const GenericTemplate = (props) => {
  const [open, setOpen] = useState();
  const [formsData, setFormsData] = useState([]);
  const dispatch = useDispatch();
  const LOB_VALUE=useSelector(state=>state?.app?.LobValue);

  useEffect(() => {
    if (!LOB_VALUE) return;
    (async () => {
      try {
        const res = await axios.get(
          `${configuration.APP_URL_CONTENTS}/forms?lob=${LOB_VALUE}`,
        );
        setFormsData(res.data[0]?.forms);
      } catch (e) {
        // console.log(e.message);
      }
    })();
  }, [LOB_VALUE]);

  const CardLinkFunc = ({ cardBtnText, cardLink, formToBeSelected }) => {
    let form;
    if (!cardLink) {
      form = formsData?.filter((item) => item._id == formToBeSelected);
    }
    return (
      <>
        {cardLink ? (
          <div className="compareItem__CTAs justify-start">
            <LinkButton
              type={"button_dark_arrow"}
              buttontext={cardBtnText}
              href={
                CarPageAllSections.includes(cardLink.split("#")[1])
                  ? `/cars/${cardLink}`
                  : cardLink
              }
              classname="button_dark_arrow fullBorder__btn"
            />
          </div>
        ) : (
          <div className="compareItem__CTAs justify-start">
            <LinkButton
              classname="button_dark_arrow fullBorder__btn"
              type={"button_dark_arrow_button"}
              buttontext={cardBtnText}
              target=""
              onClick={() => {
                dispatch(toggleForm(form[0]?.form_name));
              }}
            />
          </div>
        )}
      </>
    );
  };

  return (
    <>
      <FinanceBanner
        head={props.data?.primary_details?.banner_title}
        image={`${configuration.IMG_URL}${props.data?.images?.desk_banner}`}
        imageMob={`${configuration.IMG_STATIC_URL}assets/images/service-page/serviceMob.webp`}
        alt_image={props.data?.images?.desk_banner_alt}
        overlay={true}
      />

      <div className="hybrid_launch container-fluid md:mb-[100px] lg:mb-[100px] mb-[50px]">
        <div className="container mx-auto px-[30px] md:px-[100px]">
          <div className="hybrid_launch_wrapper md:pr-[20px]">
            <div className="hybrid_launch_heads">
              <h1 className="text-primary-blue mb-10 text-heading">
                {props.data?.primary_details?.title}
              </h1>
              <p
                className="mb-5 unreset "
                dangerouslySetInnerHTML={{
                  __html: props.data?.primary_details?.long_desc,
                }}
              ></p>
            </div>
          </div>
        </div>
      </div>

      {props.data?.highlights?.map((item) => (
        <div
          key={item._id}
          className="hybrid_safety container-fluid md:mb-[100px] lg:mb-[100px] mb-[50px]"
        >
          <div className="container mx-auto px-[30px] md:px-[100px]">
            <div className="hybrid_safety-wrapper">
              <div className="hybrid_safety_headings">
                <h1 className="mb-10 text-sub-heading">{item?.title}</h1>
                <div
                  className="hybrid_safety_heads mb-4 unreset"
                  dangerouslySetInnerHTML={{ __html: item?.desc }}
                ></div>
              </div>

              <div className="hybrid_launch_cta's grid grid-cols-1 md:grid-cols-3 mt-[32px] md:mt-[36px] lg:mt-[36px]">
                {item.ctaText && (
                  <CardLinkFunc
                    cardBtnText={item?.ctaText}
                    cardLink={item?.ctaLink}
                    formToBeSelected={item?.form}
                  />
                )}
                {item.ctaText2 && (
                  <CardLinkFunc
                    cardBtnText={item?.ctaText2}
                    cardLink={item?.ctaLink2}
                    formToBeSelected={item?.form2}
                  />
                )}
                {item.ctaText3 && (
                  <CardLinkFunc
                    cardBtnText={item?.ctaText3}
                    cardLink={item?.ctaLink3}
                    formToBeSelected={item?.form3}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      ))}

      {props.data?.faqs?.length > 0 && (
        <div className="faq container-fluid">
          <div className="container mx-auto px-[30px] md:px-[100px]">
            <div className="faq-page-wrapper md:pr-[40px] lg:pr-[40px]">
              <h1
                className={styles.faq_page_heading}
                style={{ marginBottom: "50px" }}
              >
                FREQUENTLY ASKED QUESTIONS
              </h1>
              <div className="faq-slides-wrapper mb-[56px]">
                <div
                  className={style.faq_grid_box}
                  style={{ marginBottom: "50px" }}
                >
                  {props.data?.faqs
                    ?.slice(0, open ? props.data?.faqs?.length : 4)
                    .map((elem, indx) => {
                      return (
                        <AccordionGrid
                          key={elem._id}
                          id={indx + 1}
                          question={elem?.ques}
                          answer={elem?.ans}
                          bullets
                        />
                      );
                    })}
                </div>

                <div className={styles.select_wrap}>
                  <div
                    onClick={() => setOpen(!open)}
                    className={styles.faq_page_select}
                  >
                    {/* <select className={styles.select_tag} name="" id="">
                                                    <option value="">SHOW LESS</option>
                                                </select> */}
                    {/* <p className={styles.select_tag}>{open ? "Show Less" : "Further Questions"}</p>
                                                    <div className={styles.faq_dropdown}>
                                                        <Image
                                                        src={open ? `${configuration.IMG_STATIC_URL}assets/images/faq-page/accordionUp.svg` : `${configuration.IMG_STATIC_URL}assets/images/faq-page/accordionArrow.svg`}
                                                        width={15}
                                                        height={15}
                                                        alt=""
                                                        className={`align-middle`}
                                                        />
                                                    </div> */}
                    <div className="compareItem__CTAs">
                      <LinkButton
                        type={"button_slide_arrow_button"}
                        buttontext={open ? "Mutass kevesebbet" : "Mutass többet"}
                        classname="button_dark_arrow group fullBorder__btn"
                        btnWidth="min-w-[280px]"
                        iconImage={
                          open
                            ? `${configuration.IMG_STATIC_URL}assets/images/faq-page/accordionUp.svg`
                            : `${configuration.IMG_STATIC_URL}assets/images/faq-page/accordionArrow.svg`
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default GenericTemplate;
