import useIsMobile from "../../../hooks/useIsMobile";
import { useEffect, useState } from "react";
import { formatCategoriesAndHighlights } from "../../../utils/GenUtils";
import Modal from "react-responsive-modal";
import ReactPlayer from "react-player";
import styles from "../../../styles/videos.module.css";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { BsYoutube } from "react-icons/bs";
import Image from "next/image";
import youtubeThumbnail from "youtube-thumbnail";
import Head from "next/head";

const GenericTemplate = (props) => {
  const mobile = useIsMobile();
  const [showPlayer, setShowPlayer] = useState(false);
  // const [data, setData] = useState([]);
  let data =[];
  const [selectedUrl, setSelectedUrl] = useState("");

  if(props?.data?.highlights ||props?.data?.categories )
  {
    data=formatCategoriesAndHighlights(
            props?.data?.highlights,
            props?.data?.categories,
          );
  }

  return (
    <>
      <Head>
        <title>{props?.data?.seo_settings?.meta_title}</title>
        <meta
          name="description"
          content={props?.data?.seo_settings?.meta_desc}
        />
        <meta name="keywords" content={props?.data?.seo_settings?.keywords} />
        <meta name="keywords" content={props?.data?.seo_settings?.keywords} />
      </Head>
      {/* VIDEO MODAL  */}
      <Modal
        onClose={() => setShowPlayer(false)}
        open={showPlayer}
        classNames={{
          modal: "topicSlide__modal",
        }}
      >
        <ReactPlayer
          onClick
          fullScreen={true}
          url={selectedUrl}
          autoPlay
          volume={1}
          width={mobile ? "90vw" : "65vw"}
          height={mobile ? "50vw" : "35vw"}
        />
      </Modal>

      {/* VIDEO MODELS TAB  */}
      <div className={styles.videos_wrap}>
        <div className="container">
          <h1 className={styles.videos_heads}>Videos</h1>
          <div className="videos-wrapper">
            <Tabs>
              <TabList
                className="react-tabs__tab-list"
                style={{ marginBottom: "48px" }}
              >
                {data?.map((item, i) => (
                  <Tab key={i}>{item.categoryName}</Tab>
                ))}
              </TabList>

              {data?.length > 0 &&
                data.map((data, index) => {
                  return (
                    <TabPanel
                      key={index}
                      className="grid grid-cols-1 md:grid-cols-4 gap-x-[48px] md:gap-x-[16px] lg:gap-x-[48px] gap-y-12"
                    >
                      {data?.highlights &&
                        data?.highlights.map((ele, index) => (
                          <>
                            <div
                              onClick={() => {
                                setShowPlayer(true);
                                setSelectedUrl(ele.youtube_link);
                              }}
                              className={styles.video_grid}
                            >
                              <div className="video-sec relative">
                                <span className={styles.videos_icons}>
                                  <BsYoutube size={50} color={"red"} />
                                </span>
                                <Image
                                  src={
                                    youtubeThumbnail(ele.youtube_link).medium
                                      .url
                                  }
                                  className=""
                                  // width={398}
                                  // height={221}
                                  width={mobile ? "768" : "418"}
                                  height={mobile ? "415" : "231"}
                                  alt=""
                                />
                              </div>
                              <h5 className={styles.video_grid_head}>
                                {ele.title}
                              </h5>
                              <p
                                className={styles.video_grid_paragraph}
                                dangerouslySetInnerHTML={{
                                  __html: ele.desc,
                                }}
                              />
                            </div>
                          </>
                        ))}
                    </TabPanel>
                  );
                })}
            </Tabs>
          </div>
        </div>
      </div>
    </>
  );
};

export default GenericTemplate;
