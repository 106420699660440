import React from "react";
import styles from "../../styles/faq.module.css"
import Accordion from "../accordion";
import {Constants} from "./../../helpers/constants"
const FAQ = (props) => {
  const custServices = {
    heads: Constants.faqs.FREQUENTLY_ASKED_QUESTION,
    selectText: Constants.faqs.FURTHER_QUESTIONS,
    openText: Constants.faqs.SHOW_LESS,
  };
  return (
    <>
     {props.faq_data?.faqs?.length > 0 && (
          <div id="faqs" className="faq container-fluid">
            <div className="container">
              <div className="faq-page-wrapper">
                <div className="faq-slides-wrapper mb-[56px]">
                  <div className={styles.faq_grid_box}>
                    <Accordion
                      newArray={props.faq_data?.faqs?.map((item) => {
                        return {
                          question: item.ques,
                          answer: item.ans,
                        };
                      })}
                      heads={custServices.heads}
                      selectText={custServices.selectText}
                      openText={custServices.openText}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
    </>
  );
};

export default FAQ;
