import React from "react";
import { configuration } from "../../helpers/config";
import axios from "axios";
import dynamic from "next/dynamic";
import ErrorBanner from "../../components/ErrorPage/ErrorBanner";
import CommonHomeCard from "../../components/commonHomeCard";
import { Constants } from "../../helpers/constants";
import Head from "next/head";
import CommanContentBanner from "../../components/commanContentBanner";
import RedirectComponent from "../../components/StaticRedirect";
import useIsMobile from "../../hooks/useIsMobile";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UPDATE_LOB_VALUE } from "../../redux/ActionTypes";
import AllComponents from "./templates/all_components";
import cms_form_pages from "./templates/cms_form_pages";
import cms_page_with_cta from "./templates/cms_page_with_cta";
import cms_pages from "./templates/cms_pages";
import image_gallery from "./templates/image_gallery";
import video_gallery from "./templates/video_gallery";
import { MenuFunction, FooterFunction} from '../../utils/menu';

export async function getServerSideProps(context) {
  const { cms } = context.params;

  try {
    const res = await axios.get(
      `${configuration.APP_URL_CONTENTS}/cms-pages/${cms}`,
    );

    const carsRes = await axios.get(`${configuration.APP_URL_PRODUCTS}model/`);
    const carsData = carsRes.data.data; // Assuming carsData is an array of car objects with _id property

    const { data } = res;

    const matchCarData = (models) => {
      return models.map((model) => {
        const matchedCar = carsData.find((car) => car._id === model._id);
        return matchedCar ? { ...model, ...matchedCar } : model;
      });
    };

    if (data?.multiTabs) {
      data.multiTabs = data.multiTabs.map((tab) => {
        tab.parents = tab.parents.map((parent) => {
          if (parent.models) {
            parent.models = matchCarData(parent.models);
          }
          parent.children = parent.children.map((child) => {
            if (child.models) {
              child.models = matchCarData(child.models);
            }
            return child;
          });
          return parent;
        });
        return tab;
      });
    }
    const Menudata = await  MenuFunction();
    const Footerdata = await FooterFunction();
    return {
      props: {
        data: data || {},
        Menudata: Menudata,
        Footerdata: Footerdata,
      },
    };
  } catch (err) {
    console.warn(err, "Error Fetching Preview Data");
    return {
      props: {
        error: true,
      },
    };
  }
}

export default function CMSPage(props) {
  const data = props.data;
  const isMobile = useIsMobile();
  const [themeData, setThemeData] = useState(null);
  const dispatch = useDispatch();
  const LOB_VALUE = useSelector((state) => state?.app?.LobValue);
  useEffect(() => {
    dispatch({ type: UPDATE_LOB_VALUE, payload: props?.data?.lob });
  }, []);

  useEffect(() => {
    if (!LOB_VALUE) return;
    getThemeData();
  }, [LOB_VALUE]);

  const getThemeData = async () => {
    const { data } = await axios.get(
      `${configuration.APP_URL_CONTENTS}/settings?lob=${LOB_VALUE}`,
    );
    if (data?.length > 0) {
      setThemeData(data[0]?.theme_settings?.find((x) => x.applied === true));
    }
  };

   try {
    const templateName = data?.template?.toLowerCase();
 
    // Map of template names to components
    const templateComponents = {
      video_gallery: video_gallery,
      image_gallery: image_gallery,
      cms_pages: cms_pages,
      cms_page_with_cta: cms_page_with_cta,
      cms_form_pages: cms_form_pages,
      all_components: AllComponents,
    };
  
    const TemplateComponent = templateComponents[templateName];
      if (props.error ) {
    return (
      <ErrorBanner
        image={`${configuration.IMG_STATIC_URL}errorBanner.png`}
        imageMob={`${configuration.IMG_STATIC_URL}errorMob.png`}
        error="404"
        desc={Constants.error.DESCRIPTION}
        btn={Constants.error.BUTTON_TEXT}
      />
    );
  } else {
    const propsData = {
      ...props,
      themeData,
    };
    return data?.page_settings?.page_status ? (
      // <GenericTemplate {...propsData} />
      <TemplateComponent {...props}   />
    ) : (
      <RedirectComponent />
    );
  }

  }catch (e) {
    // console.log(e.message);
  }
  return (
    <div>
      <Head>
        <title>{data?.seo_settings?.meta_title}</title>
        <meta name="description" content={data?.seo_settings?.meta_desc} />
        <meta name="keywords" content={data?.seo_settings?.keywords} />
        <meta name="keywords" content={data?.seo_settings?.keywords} />
      </Head>
      <div className="genericPage__container">
        {/* banner */}
        <CommanContentBanner
          classname={"genericPage__container--banner"}
          bannerSrc={
            isMobile ? data?.images?.mob_banner : data?.images?.desk_banner
          }
          videoLink={data?.video}
          altText={data?.images?.desk_banner_alt}
          bannerText={data?.primary_details?.banner_title}
        />

        {/* end of banner */}
        <div className="container mx-auto px-[30px] md:px-[100px]">
          {data?.highlights && data?.highlights.length > 0 && (
            <div className=" grid grid-cols-1 md:grid-cols-3 gap-5">
              {data?.highlights.map((item, index) => (
                <CommonHomeCard
                  key={index}
                  imgSrc={`${configuration.IMG_URL}${item?.image}`}
                  imgMobileSrc={`${configuration.IMG_URL}${item?.image_mob}`}
                  cardClassName="homeCms__cardLinks"
                  contentClassName={"md:max-w-[75%]"}
                  headClassName="h-[75px] lg:h-[75px] md:h-[75px] overflow-hidden"
                  descriptClass={"h-[64px] lg:h-[64px] md:h-[64px]"}
                  imgHeight={302}
                  imgWidth={545}
                  // videoLink={item?.column == 1 && !item?.isImage && page?.video}
                  imgMobileHeight={564}
                  imgMobileWidth={768}
                  cardTitle={item?.title}
                  cardDescription={item?.desc}
                  cardBtnText={Constants.pages.CARD_BUTTON_TEXT}
                  cardLink={item?.ctaLink}
                  btnType={"button_dark_arrow"}
                />
              ))}
            </div>
          )}
          <h1 className="genericPage__container--title">
            {data?.primary_details?.title}
          </h1>
          {/*<div className="genericPage__container--shortdesc">*/}
          {/*  {data?.primary_details?.desc}*/}
          {/*</div>*/}
          <div className="genericPage__container--htmlcontent">
            <div
              className="unreset"
              dangerouslySetInnerHTML={{
                __html: data?.primary_details?.long_desc,
              }}
            />
            {data?.htmlContent && (
              <div
                dangerouslySetInnerHTML={{
                  __html: data?.htmlContent,
                }}
              ></div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
