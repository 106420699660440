import useIsMobile from "../../../hooks/useIsMobile";
import React, { useEffect, useRef, useState } from "react";
import { configuration } from "../../../helpers/config";
import { formatCategoriesAndHighlights } from "../../../utils/GenUtils";
import Image from "next/image";
import styles from "../../../styles/videos.module.css";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Keyboard, Pagination, Navigation } from "swiper/modules";
import Modal from "react-responsive-modal";
import Head from "next/head";


const GenericTemplate = (props) => {
  const mobile = useIsMobile();
  const [showPlayer, setShowPlayer] = useState(false);
  // const [data, setData] = useState([]);
  let data =[]
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [tabIndex, setTabIndex] = useState(0);

  if( props?.data?.highlights || props?.data?.categories)
  {
   data = formatCategoriesAndHighlights(
            props?.data?.highlights,
            props?.data?.categories
          )

  }

  const dialogRef = useRef();

  const sliderRef = useRef(null);

  return (
    <>
      <Head>
        <title>{props?.data?.seo_settings?.meta_title}</title>
        <meta
          name="description"
          content={props?.data?.seo_settings?.meta_desc}
        />
        <meta name="keywords" content={props?.data?.seo_settings?.keywords} />
        <meta name="keywords" content={props?.data?.seo_settings?.keywords} />
      </Head>
      {/* GALLERY MODAL  */}
      <Modal
        open={showPlayer}
        onClose={() => setShowPlayer(false)}
        classNames={{
          overlay: "image_gallery_modal-overlay",
          modal: "image_gallery_modal-container",
          closeButton: "hidden",
        }}
      >
        <div className="w-[90vw] focus-visible:outline-0 mt-[150px] md:h-[85vh] md:max-h-[100%]">
        <Swiper
        effect={"coverflow"}
        autoplay={{
            delay: 1500,
            disableOnInteraction: false,
          }}
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={"auto"}
        coverflowEffect={{
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: false,
        }}
        navigation={true}
        pagination={{ clickable: true }}
        className="slider"

    spaceBetween={30}
    speed={1000} // Adjust the speed (in milliseconds)
    keyboard={{
      enabled: true,
    }}
   
    modules={[Keyboard, Pagination, Navigation]}
   
    centerPad
    initialSlide={selectedIndex}
      >

        {/* using Array */}
        {data[tabIndex]?.highlights?.map((item, i) => {
            return(
          <SwiperSlide key={i}  style={{ display: "flex", backgroundColor: "white" }}>
            <div className="image-slider__slider-image">
          <img
            height="560"
            width="768"
            alt=""
            src={configuration.IMG_URL + item.image}
            onError={(e) => {
              console.error("Image failed to load", e);
            }}
          />
        </div>
        <div className={"image-slider__slider-content"}>
          <h1 className="text-primary-blue md:text-sub-heading">
            {item?.title}
          </h1>
          <p
            dangerouslySetInnerHTML={{
              __html: item?.desc,
            }}
            className="image-slider__slider-text mb-0"
          />
        </div>

        <div
          className="image-slider_arrow absolute top-0 right-3 z-20 cursor-pointer"
          onClick={() => setShowPlayer(false)}
        >
          <Image
            src={`${configuration.IMG_STATIC_URL}slideCross.svg`}
            width={"40"}
            height={"40"}
            alt="Cross"
          />
        </div>
          </SwiperSlide>);
})}
</Swiper>
        </div>
      </Modal>

      {/* GALLERY MODELS TAB  */}
      <div className={styles.videos_wrap}>
        <div className="container">
          <h1 className={styles.videos_heads}>Galéria</h1>
          <div className="videos-wrapper">
            <Tabs onSelect={(index) => setTabIndex(index)}>
              <TabList
                className="react-tabs__tab-list"
                style={{ marginBottom: "48px" }}
              >
                {data?.map((item, i) => (
                  <Tab key={i}>{item.categoryName}</Tab>
                ))}
              </TabList>

              {data?.length > 0 &&
                data.map((data, index) => {
                  return (
                    <TabPanel
                      key={index}
                      className="grid grid-cols-1 md:grid-cols-4 gap-x-[48px] md:gap-x-[16px] lg:gap-x-[48px] gap-y-12"
                    >
                      {data?.highlights &&
                        data?.highlights.map((ele, index) => (
                          <>
                            <div
                              onClick={() => {
                                setShowPlayer(!mobile ? true : false);
                                setSelectedIndex(index);
                              }}
                              className={styles.video_grid}
                            >
                              <div className="video-sec relative">
                                <img
                                  src={
                                    mobile
                                      ? configuration.IMG_URL + ele.image_mob
                                      : configuration.IMG_URL + ele.image
                                  }
                                  className=""
                                  width={mobile ? "768" : "768"}
                                  height={mobile ? "564" : "564"}
                                  alt=""
                                  layout="responsive"
                                />
                              </div>
                              <h5 className={styles.video_grid_head}>
                                {ele.title}
                              </h5>
                              <p
                                className={styles.video_grid_paragraph}
                                dangerouslySetInnerHTML={{
                                  __html: ele.desc,
                                }}
                              />
                            </div>
                          </>
                        ))}
                    </TabPanel>
                  );
                })}
            </Tabs>
          </div>
        </div>
        {/* <Swiper
          slidesPerView={1.5}
          spaceBetween={30}
          keyboard={{
            enabled: true,
          }}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          modules={[Keyboard, Pagination, Navigation]}
          className="slider"
          centeredSlides={true}
        >
          <SwiperSlide className="item">Slide 1</SwiperSlide>
          <SwiperSlide className="item">Slide 2</SwiperSlide>
          <SwiperSlide className="item">Slide 3</SwiperSlide>
          <SwiperSlide className="item">Slide 4</SwiperSlide>
          <SwiperSlide className="item">Slide 5</SwiperSlide>
          <SwiperSlide className="item">Slide 6</SwiperSlide>
          <SwiperSlide className="item">Slide 7</SwiperSlide>
          <SwiperSlide className="item">Slide 8</SwiperSlide>
          <SwiperSlide className="item">Slide 9</SwiperSlide>
        </Swiper> */}
      </div>
    </>
  );
};

export default GenericTemplate;
