import useIsMobile from "../../../hooks/useIsMobile";
import ErrorBanner from "../../../components/ErrorPage/ErrorBanner";
import Head from "next/head";
import Image from "next/image";
import CommanContentBanner from "../../../components/commanContentBanner";
import CommonHomeCard from "../../../components/commonHomeCard";
import { configuration } from "../../../helpers/config";
import React, { useEffect, useState } from "react";
import axios from "axios";
import ReactPlayer from "react-player";
import GetQuoteStyle from "../../../components/get-quote/GetQuote.module.css";
import DynamicFormComponent from "../../../components/forms/dynamic-form/DynamicFormComponent";
import FAQ from "../../../components/Faq";
import { useSelector } from "react-redux";

export default function GenericTemplate(props) {
  const { data, error } = props;
  const isMobile = useIsMobile();
  const [formData, setFormData] = useState();
  const LOB_VALUE = useSelector((state) => state?.app?.LobValue);

  useEffect(() => {
    if (!LOB_VALUE) return; 
    (async () => {
      try {
        const res = await axios.get(
          `${configuration.APP_URL_CONTENTS}/forms?lob=${LOB_VALUE}`,
        );
        let requiredForm = res.data[0]?.forms.filter(
          (form) => form.form_name === props?.data?.forms,
        )[0];
        setFormData(requiredForm);
      } catch (e) {
        // console.log(e.message);
      }
    })();
  }, [LOB_VALUE]);

  if (error) {
    return (
      <ErrorBanner
        image={`${configuration.IMG_STATIC_URL}errorBanner.png`}
        imageMob={`${configuration.IMG_STATIC_URL}errorMob.png`}
        error="404"
        desc="Sorry, the page you’re looking for doesn’t exist"
        btn="Go Back"
      />
    );
  }
 

  return (
    <div>
      <Head>
        <title>{data?.seo_settings?.meta_title}</title>
        <meta name="description" content={data?.seo_settings?.meta_desc} />
        <meta name="keywords" content={data?.seo_settings?.keywords} />
        <meta name="keywords" content={data?.seo_settings?.keywords} />
      </Head>
      <div className="relative brand__banner mb-20">
        <div className="block md:hidden mobile_banner">
          {/* <video
            className="video-background"
            autoPlay
            loop
            muted
            playsInline
            src={`${configuration.IMG_URL}${videoUrl}`}
          /> */}

          {data?.mobile_video? (
            <ReactPlayer
              url={`${configuration.IMG_URL}${data?.mobile_video}`}
              playing={true}
              volume={0}
              height="100%"
              width="100%"
              loop={true}
              playsinline
             
            />
          ) : (
            <Image
               src={`${configuration.IMG_URL}${data?.images?.mob_banner}`}
              // layout="fill"
              // objectFit="cover"
              alt={data?.images?.mob_banner_alt}
              height={1080}
              width={768}
            />
          )}
        </div>

        <div className="hidden md:block">
          {data?.video ? (
            <ReactPlayer
              url={`${configuration.IMG_URL}${data?.video}`}
              playing={true}
              volume={0}
              height={"100vh"}
              width={"100%"}
              loop={true}
              playsinline
              
            />
          ) : (
            <Image
              src={`${configuration.IMG_URL}${data?.images?.desk_banner}`}
              alt={data?.images?.desk_banner_alt}
              height={1004}
              width={1928}
            />

          )}
        </div>
        <div className="container mx-auto ">
          <div className="brand__banner--position">
            <h1
              className={`uppercase font-suzukiBold text-heading42 md:text-bannerHeading  text-inverted mb-[5px] md:mb-0 lg:mb-[0] xl:mb-[0px]`}
              // style={
              //   windoWidth.width > 1024
              //     ? {
              //         fontSize: themeSetting?.model_title_font_size,
              //         color: themeSetting?.model_title_color,
              //       }
              //     : { color: themeSetting?.model_title_color }
              // }
            >
              {data?.primary_details?.banner_title} 
            </h1>
            </div>
            </div>
        </div>
              
<div className="get-quote-descript container md:max-w-[500px] lg:max-w-[900px] mb-[95px] unreset"><h1 className="font-[SuzukiBold] md:text-[24px] lg:text-[24px] text-[18px] text-center mb-8"  dangerouslySetInnerHTML={{ __html: data?.primary_details?.desc }}>  
  </h1></div>

      <div className="mt-[100px] container">
     
        <div className={GetQuoteStyle.get__quote__container}>
          <h1 className="text-sub-heading font-suzukiBold mb-[28px] uppercase text-defaut-color">
            {data?.primary_details?.title}
          </h1>
          <DynamicFormComponent
            hidetitle={true}
            data={formData}
            onClose={() => {
              // console.log("finished")
            }}
          />
        </div>
      </div>
      <div className="mt-8">
      <FAQ faq_data={props.data}/>
      </div>
      
    </div>
  
  );
}
